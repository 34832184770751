<template>
  <div class="inspectionManageList">
    <List ref="list" :fetchApi="fetchApi" :dataKey="'data'">
      <template v-slot:default="{ item, index }">
        <InspecitionItem
          :dataSource="item"
          @distribute="distribute"
        />
      </template>
    </List>
    <!-- 员工列表面板 -->
    <BusinessFilters
      ref="filter"
      v-model="showDistribution"
      :options="distributionOptions"
      :defaultActiveKey="['saIdList']"
      :buttonNameReset="$t('取消')"
      @reset="showDistribution = false"
      @submit="distributionFilter">
    </BusinessFilters>
  </div>
</template>
<script>
import List from '@/components/baseList/list.vue'
import InspecitionItem from './components/ListItem.vue'
import loading from '@/utils/loading'
import { mapGetters } from 'vuex'
import inspectionServices from '@/services/inspectionServices'
import BusinessFilters from '@/components/v2/business/BusinessFilters/index.vue'
import { ROLES } from '@/constants/constants'
export default {
  components: {
    List,
    InspecitionItem,
    BusinessFilters
  },
  data (){
    return {
      totalCount: 0,
      showDistribution: false,
      distributionRole: '',
      assignId: ''
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'dictHash']),
    // 是否是店长
    isSHOWOWNER() {
      const { roleLogos = [] } = this.userInfo
      return roleLogos.includes(ROLES.SHOWOWNER)
    },
    distributionOptions() {
      return [
        {
          name: '',
          type: 'B_PEOPLE_SELECTION',
          field: 'saIdList',
          isSelection: true,
          valueType: 'object',
          shopIds: '',
          height: '80vh',
          roleCodes: this.distributionRole,
          withPermission: this.isSHOWOWNER ? false : true, // 仅查询权限范围内的
        },
      ]
    },
  },
  methods: {
    // 获取数据
    fetchApi() {
      loading.showLoading()
      return inspectionServices.getInspecitionList({ isRetire: 11041002 }).then(res => {
        loading.hideLoading()
        const { data = [] } = res
        return {
          data,
          page: {
            totalCount: data.length,
            pageIndex: 1,
            pageSize: 9999,
          }
        }
      })
    },
    distribute({ id, roles, isSHOWOWNER=false }) {
      this.showDistribution = true
      this.assignId = id
      this.distributionRole = roles.join(',')
    },
    // 分配
    async distributionFilter({ saIdList=[] }) {
      const [{ id, name }] = saIdList
      const params = {
        assignorId: id,
        assignorName: name,
        id: this.assignId,
      }
      loading.showLoading()
      const res = await inspectionServices.inspectAssign(params)
      loading.hideLoading()
      this.$refs.list.onRefresh()
    },
  }
}
</script>
<style lang="less">
.inspectionManageList{
}
</style>