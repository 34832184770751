<template>
  <div class="listItem common-block">
    <div class="item blod mg-8">
      <div class="item-l">{{ dataSource.vinCode }}</div>
      <div class="item-r">{{ dataSource.carNumber }}</div>
    </div>
    <div class="item mg-8"><span>{{ $t('车系') }}：</span>{{ dataSource.seriesName }}</div>
    <div class="item mg-8"><span>{{ $t('车型') }}：</span>{{ dataSource.modelName }}</div>
    <div class="item mg-8"><span>{{ $t('外饰颜色') }}：</span>{{ dataSource.exteriorColor }}</div>
    <div class="item mg-8"><span>{{ $t('内饰颜色') }}：</span>{{ dataSource.interiorColor }}</div>
    <div class="item mg-8"><span>{{ $t('点检结果') }}：</span>{{ dataSource.inspectResult | codeFormat }}</div>
    <div v-if="dataSource.inspectorName" class="item mg-8"><span>{{ $t('点检人') }}：</span>{{ dataSource.inspectorName }}</div>
    <template v-if="dataSource.inspectResult == '2027002'">
      <div class="item mg-8 all"><span>{{ $t('点检时间') }}：</span>{{ dataSource.inspectTime }}</div>
    </template>
    <div class="btns">
      <div class="btn" @click="goHistory">点检记录</div>
      <div v-if="showResssign" class="btn" @click="assign">重新分配</div>
      <div v-if="showAssign" class="btn" @click="assign">分配人员</div>
      <div v-if="showInspection" class="btn" @click="inspection">车辆点检</div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { ROLES } from '@/constants/constants'
export default {
  props: {
    dataSource: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      totalCount: 0,
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'dictHash']),
    // 是否展示车辆点检
    showInspection() {
      return !this.dataSource.inspectResult || this.dataSource.inspectResult == '2027001' // 待点检
    },
    // 是否是店长
    isSHOWOWNER() {
      const { roleLogos = [] } = this.userInfo
      return roleLogos.includes(ROLES.SHOWOWNER)
    },
    // 是否是master
    isMASTER() {
      const { roleLogos = [] } = this.userInfo
      return roleLogos.includes(ROLES.MASTER)
    },
    // 重新分配
    showResssign() {
      return (this.isSHOWOWNER || this.isMASTER) && this.dataSource.inspectorId && this.dataSource.inspectResult == '2027001'
    },
    // 分配
    showAssign() {
      return (this.isSHOWOWNER || this.isMASTER) && !this.dataSource.inspectorId && this.dataSource.inspectResult == '2027001'
    },
  },
  methods: {
    // 点检记录
    goHistory() {
      this.$router.push({
        path: '/inspection-history',
        query: {
          id: this.dataSource.id,
          taskId: this.dataSource.taskId
        }
      })
    },
    // 分配人员
    assign(type) {
      this.$emit('distribute', {
        // roles: this.isSHOWOWNER ? [ROLES.SHOWOWNER, ROLES.SALES] : [ROLES.SALES],
        roles: [ROLES.SALES],
        id: this.dataSource.taskId
      })
    },
    inspection() {
      this.$router.push({
        path: '/inspection-operate',
        query: {
          taskId: this.dataSource.taskId
        }
      })
    },
  }
}
</script>
<style lang="less" scoped>
.listItem{
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  border-radius: 8px;
  padding: 16px 12px 0 12px;
  font-size: 13px;
  .mg-8{
    margin-bottom: 8px
  }
  .item{
    color: @black;
    width: 50%;
    &.blod{
      font-size: 14px;
      font-weight: 500;
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
    &.all{
      width: 100%;
    }
  }
  .btns{
    width: 100%;
    height: 52px;
    border-top: 1px solid rgba(13, 23, 26, 0.05);
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: @yellow-text;
    font-size: 14px;
    .btn{
      cursor: pointer;
    }
  }
}
</style>